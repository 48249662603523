import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { manageEmailPreferencesActions } from '../../../../../../actions/manage-email-preferences.actions';
import { routes } from '../../../../../../constants/routes';
import { useAppSelector } from '../../../../../../effects/useAppSelector';
import { AlertEnabledState } from '../../../../../../types/enums/AlertEnabledState';
import { StatusMessageSectionType } from '../../../../../../types/state/NotificationState';
import { isRequesting } from '../../../../../../utils';
import { RouteLeavingGuard } from '../../../../../routing';
import { StatusMessageSection } from '../../../../../status-message/StatusMessageSection';
import { PortfolioAlertsTable } from './PortfolioAlertsTable';
import { SavePortfolioAlertsButton } from './SavePortfolioAlertsButton';
import { PageConfigType } from '../../../../../../types/page-config/PageConfigType';
import { BwicFilterType } from '../../../../../../types/filters/FilterState';
import { usePageConfig } from '../../../../../common/pageConfig/usePageConfig';
import { PortfolioUserConfigFilter } from '../../../../../../types/user-config/UserConfigFilter';
import { usePortfolioAlertsChangedState } from './usePortfolioAlertsChangedState';
import { UnsubscribePortfolioAlertsButton } from './UnsubscribePortfolioAlertsButton';
import { textLabels } from '../../../../../../constants/text-labels';
import { SubscriptionFeature } from '../../../../../../types/billing/SubscriptionFeature';
import { AlertOption } from '../../../../../../types/email-preferences/EmailPreferences';
import { EmailPreferencesRadioGroup } from '../EmailPreferencesRadioGroup';
import { emailPreferencesUtils } from '../../../../../../utils/email-preferences.utils';

export function PortfolioTab() {
    const dispatch = useDispatch();
    const history = useHistory();
    const { config, requestState, updateRequestState } = usePageConfig(PageConfigType.Portfolio, true, BwicFilterType.Portfolio);

    const initialPortfolioBwicAlertState = useAppSelector(s => s.manageEmailPreferences.initialPreferences.portfolioBwicAlertState);
    const initialPortfolioInvnetoryAlertState = useAppSelector(s => s.manageEmailPreferences.initialPreferences.portfolioDealersInventoryAlertState);
    const initialPortfolioIssuanceMonitorAlertState = useAppSelector(s => s.manageEmailPreferences.initialPreferences.portfolioIssuanceMonitorAlertState);
    const initialPortfolioRollerDeadlineAlertState = useAppSelector(s => s.manageEmailPreferences.initialPreferences.portfolioRollerDeadlineAlertState);

    const savePreferencesRequestState = useAppSelector(s => s.manageEmailPreferences.requestStateSaveEditPreferences);

    const portfolioIssuanceMonitorAlertState = useAppSelector(s => s.manageEmailPreferences.editPreferences.portfolioIssuanceMonitorAlertState);
    const portfolioRollerDeadlineAlertState = useAppSelector(s => s.manageEmailPreferences.editPreferences.portfolioRollerDeadlineAlertState);

    const issuanceMonitorAlertOption = emailPreferencesUtils.convertAlertEnabledStateToAlertOption(portfolioIssuanceMonitorAlertState, AlertOption.Instant);
    const rollerDeadlineAlertOption = emailPreferencesUtils.convertAlertEnabledStateToAlertOption(portfolioRollerDeadlineAlertState, AlertOption.Instant);

    const hasChanges = usePortfolioAlertsChangedState();

    const handleChangeImAlert = (value: AlertOption) => {
        dispatch(
            manageEmailPreferencesActions.setPortfolioIssuanceMonitorAlerts(
                emailPreferencesUtils.convertAlertOptionToAlertEnabledState(value),
            ),
        );
    };

    const handleChangeRollerDeadlineAlert = (value: AlertOption) => {
        dispatch(
            manageEmailPreferencesActions.setPortfolioRollerDeadlineAlerts(
                emailPreferencesUtils.convertAlertOptionToAlertEnabledState(value),
            ),
        );
    };

    return (
        <>
            <div className="flex-row save-changes">
                {(initialPortfolioBwicAlertState === AlertEnabledState.PartiallyEnabled ||
                    initialPortfolioInvnetoryAlertState === AlertEnabledState.PartiallyEnabled ||
                    initialPortfolioIssuanceMonitorAlertState === AlertEnabledState.PartiallyEnabled ||
                    initialPortfolioRollerDeadlineAlertState === AlertEnabledState.PartiallyEnabled) && (
                    <StatusMessageSection type={StatusMessageSectionType.Info}>
                        Some alerts are disabled on partial check, see <Link to={routes.portfolio}>Portfolio</Link> page
                        for details and management.
                    </StatusMessageSection>
                )}
                <div className="flex-item-right">
                    <UnsubscribePortfolioAlertsButton
                        disabled={isRequesting(requestState, updateRequestState, savePreferencesRequestState)}
                    />
                    <SavePortfolioAlertsButton className="btn-sm" />
                </div>
            </div>
            <div className="controls-wrap">
                <EmailPreferencesRadioGroup
                    value={issuanceMonitorAlertOption}
                    alertOptions={[AlertOption.Never, AlertOption.Instant]}
                    alertOptionInfo={{
                        [AlertOption.Instant]: textLabels.savedFiltersPortfolioIssuanceMonitorInstantAlert,
                    }}
                    groupNameIndentifier="single-portfolio-issuance-monitor-alert-state"
                    title="Issuance Monitor"
                    description={textLabels.savedFiltersPortfolioIssuanceMonitorAlertDescription}
                    onChange={handleChangeImAlert}
                    partially={portfolioIssuanceMonitorAlertState === AlertEnabledState.PartiallyEnabled}
                    requiredFeatures={[
                        SubscriptionFeature.IssuanceMonitorFullAccess,
                        SubscriptionFeature.PortfolioAlerts,
                    ]}
                />
                <EmailPreferencesRadioGroup
                    value={rollerDeadlineAlertOption}
                    alertOptions={[AlertOption.Never, AlertOption.Instant]}
                    alertOptionInfo={{
                        [AlertOption.Instant]: textLabels.savedFiltersPortfolioRollerDeadlineDailyAlert,
                    }}
                    groupNameIndentifier="single-portfolio-roller-deadline-alert-state"
                    title="Roller Deadline"
                    description={textLabels.savedFiltersPortfolioRollerDeadlineAlertDescription}
                    onChange={handleChangeRollerDeadlineAlert}
                    partially={portfolioRollerDeadlineAlertState === AlertEnabledState.PartiallyEnabled}
                    requiredFeatures={[
                        SubscriptionFeature.IssuanceMonitorFullAccess,
                        SubscriptionFeature.PortfolioAlerts,
                    ]}
                />
                <div className="controls-wrap">
                    <div className="controls-wrap-head">
                        <h4 className="controls-wrap-title">
                            BWIC / Inventory Alerts
                        </h4>
                    </div>
                    <p className="controls-wrap-info">
                        Receive email notifications when security from your portfolio is on a BWIC or has been added,
                        updated, or traded in the Dealer Inventory List.
                    </p>
                </div>
                <PortfolioAlertsTable savedFilters={(config?.filters as PortfolioUserConfigFilter[]) ?? []} />
                <RouteLeavingGuard
                    navigate={(pathname: string) => {
                        dispatch(manageEmailPreferencesActions.rollbackPortfolio());
                        history.push(pathname);
                    }}
                    shouldBlockNavigation={() => hasChanges}
                />
            </div>
        </>
    );
}
